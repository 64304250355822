import React from 'react'
import ImgCDN from "../../components/ImgCDN";
import Link from 'next/link';
import RippleLayout from "../../components/ripple/RippleLayout";
import { NAME_PROJECT } from "../../settings/global";
import styles from './AppsCategories.module.scss'
import { Block, Content } from "../../layouts/Content";
import AspectRatioBox from "../../components/AspectRatioBox";
import { NAV_STACK_LIST } from "../../settings/navigation";

function AppsCategories({categories: categoriesData}) {

    const categories = {
        bloggers: {icon: 'blogger', backgroundColor: '#f3f9fd', leadColor: '#0f6fff'},
        marketing: {icon: 'shout', backgroundColor: '#ffebeb', leadColor: '#ff3031'},
        smes: {icon: 'configs', backgroundColor: '#fefae8', leadColor: '#ffcb10'},
        devs: {icon: 'server', backgroundColor: '#fff7e8', leadColor: '#ffae10'},
        com: {icon: 'e-company', backgroundColor: '#ebfeff', leadColor: '#36f3ff'},
        devops: {icon: 'windows', backgroundColor: '#f4fde8', leadColor: '#89e315'},
        gamer: {icon: 'gamepad', backgroundColor: '#f5f4ff', leadColor: '#9a8bff'},
        education: {icon: 'education', backgroundColor: '#fff0eb', leadColor: '#fc6e51'},
        New: {icon: 'error', backgroundColor: '#f5f4ff', leadColor: '#9a8bff'},
    };

    const renderTagsWithRipple = () => {

        return categoriesData.map((category, index) => {

            let url = `${NAV_STACK_LIST}?tagsCriterion=category&descendant=true&tags=${category['tags']}`;
            let icon = categories[category.id]?.icon || 'error';

            return (
                <li className={styles.content__item} key={index}>
                    <AspectRatioBox ratio={[2, 1.3]} className={styles.category}>
                        <Link
                            prefetch={false}
                            href={url}
                            className={styles.category__container}
                            draggable='false'
                            aria-label={`Show ${NAME_PROJECT} ${category.title} solutions`}
                            style={{'--category-outline-color': categories[category.id]?.leadColor}}>

                            <RippleLayout
                                displayType='outlined'
                                className={styles.category__container__ripple}
                                visuals={{
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: 'var(--borderRadiusContainerInnerEdge)',
                                    colorBasic: [categories[category.id]?.leadColor || null]
                                }}
                            >
                                <div className={styles.category__container__icon}>
                                    <ImgCDN
                                        className={styles.category__container__icon__image}
                                        src={`/img/icons/categories/${icon}.svg`}
                                        alt={`${category.title} icon image`}
                                    />
                                </div>
                                <h3 className={styles.category__container__title}>{category.title}</h3>
                            </RippleLayout>

                        </Link>
                    </AspectRatioBox>
                </li>
            );
        });
    };
    return (
        <Block backgroundColor='var(--colorBackgroundContent)'>
            <Content className={styles.layout}>
                <h2 className={styles.title}>Solutions</h2>
                <ul className={styles.content}>
                    {renderTagsWithRipple()}
                </ul>
            </Content>
        </Block>
    );
}

export default AppsCategories