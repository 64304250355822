import React from 'react';
import Link from 'next/link';
import Button from "../../components/ripple/Button";
import { NAME_PROJECT } from "../../settings/global";
import styles from './StacksCarousel.module.scss'
import Icon from "../../components/Icon";
import ImgCDN from "../../components/ImgCDN";
import { generateSlug } from "../../helpers/common";
import { BUILDER_DATA } from "../../settings/content/deploymentStacker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Block, Content } from "../../layouts/Content";
import { NAV_STACK_LIST } from "../../settings/navigation";

function CarouselItem({stack, custom}) {

    const {accessibility} = stack;
    const link = `${NAV_STACK_LIST}/${generateSlug(stack.name)}`;

    const showAdditionalIcons = () => {

        const isDev = stack.code.includes('-dev');
        const isExclusive = stack.code.includes('-formula');

        if (!isDev && !isExclusive) return null;

        return (
            <div className={styles['app__additional']}>
                {
                    isDev &&
                    <div className={styles['app__additional__dev']}>
                        <i><FontAwesomeIcon icon={'fas fa-cubes'}/></i>
                    </div>
                }
                {
                    isExclusive &&
                    <div className={styles['app__additional__formula']}>
                        <ImgCDN src='/img/logo.svg' aria-hidden="true"/>
                    </div>
                }
            </div>
        )
    }

    const showDebugInfo = () => {

        if (!accessibility.comingSoon && !accessibility.hidden) return null;

        return (
            <div className={styles['app__status']}>
                {
                    accessibility.comingSoon && !accessibility.hidden &&
                    <i><FontAwesomeIcon icon={'fas fa-angle-double-up'}/></i>
                }
                {accessibility.hidden && <i><FontAwesomeIcon icon={'fas fa-eye-slash'}/></i>}
            </div>
        )
    }

    return (
        <div className={styles.appLayout}>
            <div className={styles.appLayout__container}>
                <Link
                    href={link}
                    prefetch={false}
                    className={styles.app}
                    draggable='false'
                    aria-label={`Learn about ${stack.name} by ${NAME_PROJECT}`}
                >
                    <div className={styles.app__name}>
                        <p className={styles.app__name__text}>{stack.name}</p>
                    </div>
                    <div className={styles.app__container}>
                        <Icon
                            className={styles.app__container__image}
                            icon={stack.icon}
                            alt={stack.name}
                            fallbackImages={!!custom ? ['/images/custom-stack.svg'] : undefined}
                            // height={70}
                            // width={70}
                            // optimize
                        />
                    </div>
                    {showAdditionalIcons()}
                    {showDebugInfo()}
                </Link>
            </div>
        </div>
    );
}

function StacksCarousel({stacks}) {
    return (
        <Block>
            <Content id='homepage-carousel' className={`${styles.carousel}`}>
                <p className={styles.carousel__title}>Quick Deployments</p>
                <div className={styles.carousel__container}>
                    <CarouselItem stack={BUILDER_DATA} key={`custom-stack`} custom/>
                    {stacks.map((item, index) => <CarouselItem stack={item} key={index}/>)}
                </div>
                <Link
                    prefetch={false}
                    href={NAV_STACK_LIST}
                    className={styles.carousel__search}
                    aria-label='Navigate to all stacks'
                >
                    <Button icon={`fas fa-search`} importedIcon aria-label='Navigate to all stacks'/>
                </Link>
            </Content>
        </Block>
    );
}

export default StacksCarousel