import React from "react"
import styles from './FirstBlock.module.scss'
import { Block, Content } from "../../layouts/Content";
import DraftHTML from "../../components/draftEditor/export/DraftHTML";
import ImgCDN from "../../components/ImgCDN";
import { DEBUG, URL_UPLOADED_PICTURES_FOLDER } from "../../settings/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FirstBlock({data}) {

    if (!data.content || !data.image?.image) return null;

    return (
        <>
            <Block className={styles.layout}>
                <Content className={styles.content} style={{padding: '40px 20px'}} noSection>
                    <DraftHTML
                        className={`content-editor ${styles.content__text}`}
                        content={data.content}
                    />
                    <div className={styles.content__image}>
                        <ImgCDN
                            src={`${URL_UPLOADED_PICTURES_FOLDER}/${data?.image?.image}`}
                            alt='TryDirect preview image'
                        />
                        {DEBUG && <div className={styles.content__image__debug}>.webp animation would look great here!</div>}
                    </div>
                </Content>
            </Block>
            {
                !!data.blocks?.length &&
                <Block className={`${styles.layout} ${styles.blocksLayout}`}>
                    <Content className={styles.blocks} noSection>
                        <div className={styles.blocks__container}>
                            {
                                data.blocks.map((col) => (
                                    <div
                                        style={{'--first-block-max-width': `calc(100%/${data.blocks.length})`}}
                                        className={styles.blocks__container__item}
                                        key={col.timestamp}
                                    >
                                        <div className={styles.block}>
                                            <div className={styles.block__head}>
                                                <div className={styles.block__head__icon}>
                                                    <FontAwesomeIcon
                                                        className={styles.block__head__icon__image}
                                                        icon={col.icon}
                                                    />
                                                </div>
                                                <h2 className={styles.block__head__title}>{col.title}</h2>
                                            </div>
                                            <p className={styles.block__content}>
                                                {col.text}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </Content>
                </Block>
            }
        </>
    )
}