// Partners
export const PARTNER_LINK_UPCLOUD = 'https://www.upcloud.com/register/?promo=29GFG3';
export const PARTNER_LINK_SCALEWAY = 'https://cloud.scaleway.com/#/signup';
export const PARTNER_LINK_GUVER = 'https://guver.com.br/';
export const PARTNER_LINK_FREELINUXBLOG = 'https://freelinuxblog.com/';
// Post-deployment guide
export const HOWTO_CHANGE_DNS_GODADDY = 'https://www.godaddy.com/help/change-an-a-record-19239';
export const HOWTO_CHANGE_DNS_NAMECHEAP = 'https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-an-a-address-record-for-my-domain';
export const HOWTO_CHANGE_DNS_BLUEHOST = 'https://my.bluehost.com/hosting/help/whats-an-a-record';
export const HOWTO_CHANGE_DNS_NAME = 'https://www.name.com/support/articles/205188538-Pointing-your-domain-to-hosting-with-A-records';
export const HOWTO_CHANGE_DNS_DREAMHOST = 'https://support.cloudways.com/creating-a-record-dreamhost/';
export const HOWTO_CHANGE_DNS_A = 'https://www.google.com/search?q=change+%22A%22+record&oq=change+%22A%22+record+&aqs=chrome..69i57j0l5.10485j0j7&sourceid=chrome&ie=UTF-8';
export const URL_CONSULT_DEPLOYMENT = 'https://calendly.com/info-c2g/30min';

// export const URL_FEATURE_REQUEST = 'https://bitbucket.org/Optimum-Web/try-direct-features-bugs/issues/';
// export const URL_FEEDBACK = 'https://bitbucket.org/Optimum-Web/try-direct-features-bugs/issues/';