import { NAME_PROJECT, URL_BASE } from "../global";
import { DESCRIPTION_DEFAULT } from "../../helpers/templates";
import { SOCIAL_DATA } from "./socialProviders";

export const COMPANY_PHONE_NUMBER = "+37360775719";
export const COMPANY_EMAIL = "info@try.direct";
export const COMPANY_ADDRESS = "Calea Iesilor 8 of.3";
export const COMPANY_COUNTRY = "Moldova";
export const COMPANY_CODE = "MD";
export const COMPANY_CITY = "Chisinau";
export const COMPANY_POSTAL_CODE = "2069";

export const SEO_ORGANISATION = {
    "@type": 'Organization',
    "name": NAME_PROJECT,
    "url": URL_BASE,
    "logo": {
        "@type": "ImageObject",
        "url": `${URL_BASE}/img/logo.svg`
    },
    "description": DESCRIPTION_DEFAULT,
    "address": {
        "@type": "PostalAddress",
        "streetAddress": COMPANY_ADDRESS,
        "addressLocality": COMPANY_CITY,
        "addressRegion": COMPANY_CITY,
        "postalCode": COMPANY_POSTAL_CODE,
        "addressCountry": COMPANY_CODE
    },
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": COMPANY_PHONE_NUMBER,
        "contactType": "customer service"
    },
    "sameAs": SOCIAL_DATA.filter(p => !!p.link).map(p => p.link)
};